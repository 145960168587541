<template>
    <Button type="button" v-tooltip.top="'edit detail tagihan studi'" icon="pi pi-pencil" label="" class="p-button-warning ml-2" @click="display = true" />
    <div class="col-12 lg:col-12">
        <Dialog header="Edit Detail Tagihan Studi Mahasiswa" v-model:visible="display" :breakpoints="{ '960px': '75vw' }" :style="{ width: '40vw' }" :modal="true" @keydown.enter.prevent="update()" @hide="v$.$reset()">
            <div class="col-12 lg:col-12 p-fluid">
                <span class="p-float-label mt-4">
                    <InputText id="NIM_DTS" disabled type="text" styleClass="wid100" autofocus v-model.lazy="data_edit.NIM_DTS" />
                    <label for="NIM_DTS">NIM</label>
                </span>
                <span class="p-float-label mt-4">
                    <InputText id="nama_mahasiswa_dts" disabled v-model.lazy="data_edit.nama_mahasiswa_dts" />
                    <label for="nama_mahasiswa_dts">Nama Mahasiswa/wi</label>
                </span>
                <span class="p-float-label mt-4">
                    <InputText id="kode_pendaftaran_dts" disabled v-model.lazy="data_edit.kode_pendaftaran_dts" />
                    <label for="kode_pendaftaran_dts">Kode Pendaftaran</label>
                </span>
                <span class="p-float-label mt-4">
                    <InputText id="kode_prodi" disabled v-model.lazy="data_edit.kode_prodi" />
                    <label for="kode_prodi">Kode Prodi</label>
                </span>
                <span class="p-float-label mt-4">
                    <InputText id="tahun_ak" disabled v-model.lazy="data_edit.tahun_ak" />
                    <label for="tahun_ak">Angkatan</label>
                </span>
                <span class="p-float-label mt-4">
                    <InputText id="nama_prodi" disabled v-model.lazy="data_edit.nama_prodi" />
                    <label for="nama_prodi">Prodi</label>
                </span>
                <span class="p-float-label mt-4">
                    <InputText id="nama_kategori" disabled v-model.lazy="data_edit.nama_kategori" />
                    <label for="nama_kategori">Kategori Bayar</label>
                </span>
                <span class="p-float-label mt-4">
                    <InputText id="jenis_kategori_biaya" disabled v-model.lazy="data_edit.jenis_kategori_biaya" />
                    <label for="jenis_kategori_biaya">Jenis Kategori Biaya</label>
                </span>
                <span class="p-float-label mt-4">
                    <InputText id="tahun_kb" disabled v-model.lazy="data_edit.tahun_kb" />
                    <label for="tahun_kb">Tahun Kalender Bayar</label>
                </span>
                <span class="p-float-label mt-4">
                    <InputText id="semester" disabled v-model.lazy="data_edit.semester" />
                    <label for="semester">Semester</label>
                </span>
                <span class="p-float-label mt-4">
                    <Calendar :showIcon="true" @date-select="set_date('bayar_mulai', $event)" :showButtonBar="true" dateFormat="yy-mm-dd" v-model.lazy="data_edit.tanggal_mulai"></Calendar>
                    <label for="tanggal_tutup">Tanggal Pembukaan</label>
                </span>
                <span class="p-float-label mt-4">
                    <Calendar :showIcon="true" @date-select="set_date('bayar_tutup', $event)" :showButtonBar="true" dateFormat="yy-mm-dd" v-model.lazy="data_edit.tanggal_tutup"></Calendar>
                    <label for="tanggal_tutup">Tanggal Penutupan</label>
                </span>
                <span class="p-float-label mt-4">
                    <InputNumber
                        id="tagihan"
                        type="text"
                        styleClass="wid100"
                        autofocus
                        locale="id"
                        v-model.lazy="data_edit.tagihan"
                        @blur="v$.data_edit.tagihan.$touch()"
                        :class="{ 'p-invalid': v$.data_edit.tagihan.$invalid ? !v$.data_edit.tagihan.$anyError : null }"
                    />
                    <label for="tagihan">Tagihan</label>
                    <small v-if="v$.data_edit.tagihan.$invalid" :class="{ 'p-error': v$.data_edit.tagihan.$invalid ? !v$.data_edit.tagihan.$anyError : null }">{{ checkValidasi('tagihan') }}</small>
                </span>
            </div>
            <template #footer>
                <Button label="Batal" :loading="busy" @click="display = false" icon="" class="p-button-secondary" />
                <Button label="Simpan" :loading="busy" @click="update()" icon="" class="p-button-success" :disabled="!isValid" />
            </template>
        </Dialog>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
export default {
    props: ['dataEdit'],
    setup() {
        return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
    },
    emits: {
        alert: null,
    },
    data() {
        return {
            display: false,
            busy: false,
        };
    },
    validations() {
        return {
            data_edit: {
                tanggal_mulai: {
                    required,
                },
                tanggal_tutup: {
                    required,
                },
                tagihan: {
                    required,
                },
            },
        };
    },
    computed: {
        formString() {
            return JSON.stringify(this.data);
        },
        isValid() {
            return !this.v$.$invalid;
        },
        isDirty() {
            return this.v$.$anyDirty;
        },
        data_edit() {
            let vm = this;
            let x = this.dataEdit;
            x.tanggal_mulai = vm.$moment(x.bayar_mulai).format('ll');
            x.tanggal_tutup = vm.$moment(x.bayar_tutup).format('ll');
            return x;
        },
    },
    mounted() {
        this.v$.$reset();
    },
    methods: {
        set_date(x, y) {
            this.data_edit[x] = y;
        },
        update() {
            let vm = this;
            vm.busy = true;
            if (vm.isValid && vm.isDirty) {
                vm.data_edit.id = vm.data_edit.detailsTagihanStudiId;
                vm.data_edit.bayar_tutup = vm.$moment(vm.data_edit.bayar_tutup).endOf('days').format()
                vm.$axiosbilling
                    .post('detailsTagihanStudi/update', vm.data_edit)
                    .then((res) => {
                        console.log(res.data.status);
                        if (res.data.status == 200) {
                            if (res.data.message == 'sukses') {
                                vm.display = false;
                                vm.busy = false;
                                vm.$emit('alert', { severity: 'success', summary: 'Konfirmasi', detail: 'Berhasil Mengubah Tagihan Mahasiswa', life: 3000 });
                            } else {
                                vm.busy = false;
                                vm.display = false;
                                vm.$emit('alert', { severity: 'warn', summary: 'Konfirmasi', detail: res.data.message, life: 3000 });
                            }
                        } else {
                            vm.busy = false;
                            if (res.data.status == 204) {
                                vm.$emit('alert', { severity: 'warn', summary: 'Konfirmasi', detail: res.data.message, life: 3000 });
                            }
                            vm.display = false;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        vm.busy = false;
                        vm.display = false;
                        vm.$emit('alert', { severity: 'error', summary: 'Konfirmasi', detail: 'Terjadi kesalahan pada server', life: 3000 });
                    });
            } else {
                vm.busy = false;
                vm.$emit('alert', { severity: 'error', summary: 'Peringatan', detail: 'Masih terdapat isian data yang tidak valid', life: 3000 });
            }
        },
        checkValidasi(fieldName) {
            const field = this.v$.data_edit[fieldName];
            let x = field.$errors[0].$validator;
            if (x == 'required') {
                return '* Data wajib diisi';
            } else if (x == 'email') {
                return '* Data harus berbentuk email';
            } else if (x == 'numeric') {
                return '* Data harus terdiri hanxa dari angka';
            } else if (x == 'minLength') {
                return `* Data minimal ${field.$errors[0].$params.min} digits`;
            } else if (x == 'maxLength') {
                return `* Data maksimal ${field.$errors[0].$params.max} digits`;
            } else if (x == 'alphaNum') {
                return `* Data tidak boleh mengandung spasi atau simbol`;
            } else if (x == 'official') {
                return `* Data tidak boleh mengandung spasi atau simbol`;
            }
        },
    },
};
</script>
